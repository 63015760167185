import { GET_OPERATOR_CONFIGURATION } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchOperatorSpecificConfigurationFailure, fetchOperatorSpecificConfigurationSuccess} from "./reducer";

export const fetchOperatorSpecificConfigurations = (setLoading) => async (dispatch) => {
    try {
        const operatorId = localStorage.getItem("operatorId")
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${GET_OPERATOR_CONFIGURATION}/${operatorId}`,'GET')
        setLoading(false)
        dispatch(fetchOperatorSpecificConfigurationSuccess(response))
    }
    catch (error) {
        setLoading(false)
        dispatch(fetchOperatorSpecificConfigurationFailure(error.message))
    }
}