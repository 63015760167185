import React from "react";
import { Box } from "@mui/material";
import { getCellStyle } from "../../../Utils/DecideColor";
import { Input } from "reactstrap";

export const ResourcecolumnData = (isCurrency,selectAllResource, setSelectAllResource, resources, setSelectedResource, selectedResource, checkboxRef, isMobile,modalOpen) => {
  return [
    {
      accessorKey: 'checkbox',
      header: 'checkbox',
      Header: ({ row }) => (
        <Input type="checkbox" ref={checkboxRef} className=' border-black checkbox-size rounded-1' checked={selectAllResource} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} onClick={() => {
          setSelectAllResource(!selectAllResource)
          if (!selectAllResource) {
            setSelectedResource(resources)
          }
          else {
            setSelectedResource([])
          }
        }} />
      ),
      enableColumnActions: false,
      enableColumnDragging: false,
      Cell: ({ row }) => (
        <Input type="checkbox" className=' border-black checkbox-size  rounded-1' checked={selectedResource.some(p => p.name === row.original.name)} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} onClick={() => {
          handleCheckboxChange(row, selectedResource, setSelectedResource)
        }
        } />
      ),
      size: isMobile ? 50 : modalOpen ? 32 :18
    },
    {
      accessorKey: "name",
      header: "Resource Name",
      size: 150,
    },
    {
     accessorKey: "resourceType",
      accessorFn: (resource, index) => `${isCurrency[index]}`,
      header: "Resource Type",
      size: 150,
      Cell: ({ cell }) => (
        <Box
          sx={(theme) => ({ 
            ...getCellStyle(cell.getValue(),"Resource"),
          })}
        >
          {cell.getValue()}
        </Box>
      ),
    },
    {
      accessorKey: "resourceId",
      header: "Code",
      size: 150,
    },
    {
      accessorKey: "ceiling",
      header: "Ceiling",
      size: 150,
    },
    {
      accessorKey: "floor",
      header: "Floor",
      size: 150,
    },
  ];
};

export const handleCheckboxChange = (row, selectedResource, setSelectedResource) => {
  let isChecked = selectedResource.some(p => p.name === row.original.name);
  let updatedSelectedResource = isChecked
    ? selectedResource.filter(p => p.name !== row.original.name)
    : [...selectedResource, row.original];
  setSelectedResource(updatedSelectedResource);
};
