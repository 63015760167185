import { setSuccessMessage } from "../../toastMessage/action";
import { toast } from "react-toastify";
import { fetchCreateOperatorSucess, fetchCreateOperatorFailure } from "./reducer";
import { OPERATOR_CREATE_AND_EDIT_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { uploadDocument } from "../../customer/documentupload/thunk";
import { createConfigOperator } from "../ConfigurationCreateOperator/thunk";

export const createOperator = (jsonObj, setLoading, navigate, file, finalValues, createNow) => (dispatch) => {
    setLoading(true)
    const roleName = localStorage.getItem("roleName")

    determineBaseUrlAndPerformApiCall(`${OPERATOR_CREATE_AND_EDIT_API}`, 'POST', jsonObj).then((response) => {

        if (!response.error) {
            setLoading(false)
            const formData = new FormData()
            formData.append("ID", response?.data?.operatorId)
            formData.append("file", file);
            if (roleName === "systemUser") {
                formData.append("userType", "operator")
            }
            formData.append("documentName", "logo");
            formData.append("documentType", "logo");
            formData.append('uploadedBy', response?.data?.tradingName)

            const formatValue = (value) => {
                if (typeof value === 'boolean') {
                    return value ? "True" : "False";
                }
                if(value === undefined) {
                    return "False"
                }
                return value;
            };
            const createConfigArray = (finalValue) => {
                const config = {};
            
                const taskEnabledValue = finalValue["TaskEnabled"];
                const brmTaskEnabledValue = finalValue["BRMTaskEnabled"];
            
                Object.entries(finalValue).forEach(([key, value]) => {
                    let managementName = '';
            
                    if (key.startsWith("payment")) {
                        managementName = "paymentManagement";
                    } else if (key.startsWith("identity")) {
                        managementName = "identityManagement";
                    } else if (key.startsWith("whatsApp")) {
                        managementName = "whatsAppManagement";
                    } else if (key.startsWith("notification")) {
                        managementName = "notificationManagement";
                    } else if (key.startsWith("task") || key.includes("Task")) {
                        managementName = "TaskManagement";
                    }
            
                    if (managementName) {
                        if (!config[managementName]) {
                            config[managementName] = {
                                name: managementName,
                                value: [],
                                operatorId: response?.data?.operatorId,
                                type: response?.data?.domain,
                                level: "Operator"
                            };
                        }
            
                        if (managementName === "TaskManagement") {
                            if (!config[managementName].value.some(item => item.key === "TaskEnabled")) {
                                const formattedTaskValue = formatValue(taskEnabledValue);
                                config[managementName].value.push({
                                    key: "TaskEnabled",
                                    value: [formattedTaskValue]
                                });
                            }
            
                            if (!config[managementName].value.some(item => item.key === "BRMTaskEnabled")) {
                                const formattedBRMTaskValue = formatValue(brmTaskEnabledValue);
                                config[managementName].value.push({
                                    key: "BRMTaskEnabled",
                                    value: [formattedBRMTaskValue]
                                });
                            }
                        } else {
                            const formattedValue = formatValue(value);
                            if (!config[managementName].value.some(item => item.key === key)) {
                                config[managementName].value.push({
                                    key: key,
                                    value: [formattedValue]
                                });
                            }
                        }
            
                        if (managementName === "identityManagement") {
                            if (!config[managementName].value.some(item => item.key === 'Enable')) {
                                config[managementName].value.push({
                                    key: 'Enable',
                                    value: [value ? "True" : "False"]
                                });
                            }
                        }
            
                        if (managementName === "paymentManagement") {
                            if (!config[managementName].value.some(item => item.key === 'PaymentStatus')) {
                                config[managementName].value.push({
                                    key: 'PaymentStatus',
                                    value: [value ? "ACTIVE" : "INACTIVE"]
                                });
                            }
                        }
                    }
                });
            
                return Object.values(config);
            };
            
            const configArray = createConfigArray(finalValues);
            const jsonObj = {
                "configurations": configArray,
                "operatorId": response?.data?.operatorId
            }

            const tasks = [];

            if (file) {
                tasks.push(dispatch(uploadDocument(formData, setLoading, response?.data?.operatorId, "Operator", navigate)));
            }

            if (createNow) {
                tasks.push(dispatch(createConfigOperator(jsonObj, setLoading, navigate)));
            }

            if (tasks.length > 0) {
                Promise.all(tasks).then(() => {
                    // navigate('/operator-listing');
                    dispatch(setSuccessMessage(true));
                });
            } else {
                navigate('/operator-listing');
                dispatch(setSuccessMessage(true));
            }

            dispatch(fetchCreateOperatorSucess(response));

        } else if (response.error) {
            dispatch(fetchCreateOperatorFailure(response.error.message));
            setLoading(false);
            toast.error(`${response.error.message}`);
        }
    }).catch((errorResp) => {
        console.error('Error occurred:', errorResp);
        setLoading(false);
    });
}
